import {
  SET_MENTIONS,
  SET_TAGS,
  SET_SEARCH_PENDING_STATUS,
  SET_SEARCH_RESULTS,
  SET_ALL_TAGS,
  RESET_SEARCH_PAGE,
  EDIT_SEARCH_PAGE,
  SET_GLOBAL_SEARCH_PENDING_STATUS,
  REMOVE_SEARCH_RESULT,
  SET_SEARCH_QUERY,
} from "../../action-types/search"
import {
  EDIT_TAG_SUCCESS,
  REMOVE_TAG_SUCCESS,
  SELECT_TAG,
  SET_NEW_TAG,
} from "../../action-types/tags"
import _ from "lodash"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  query: "",
  selectedTag: {},
  pending: false,
  tags: [],
  mentions: [],
  allTags: [],
  isPending: false,
  results: {
    communication: [],
    company: [],
    contact: [],
    job: [],
    note: [],
  },
}

export const searchReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return initState
    }
    case SET_SEARCH_QUERY: {
      return {
        ...state,
        query: action.payload,
      }
    }
    case REMOVE_SEARCH_RESULT: {
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload.key]: state.results[action.payload.key].filter(
            (i) => i.id !== action.payload.id
          ),
        },
      }
    }
    case SET_GLOBAL_SEARCH_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
      }
    }
    case SET_NEW_TAG: {
      return {
        ...state,
        allTags: [...state.tags].concat(action.payload),
      }
    }
    case EDIT_SEARCH_PAGE: {
      if (!state.results[action.key]) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        results: {
          ...state.results,
          [action.key]: !_.isEmpty(state.results[action.key])
            ? state.results[action.key].map((item) =>
                item.id === action.payload.id ? action.payload : item
              )
            : [],
        },
      }
    }
    case RESET_SEARCH_PAGE: {
      return {
        ...state,
        results: initState.results,
      }
    }
    case SELECT_TAG: {
      return {
        ...state,
        selectedTag: action.payload,
      }
    }
    case EDIT_TAG_SUCCESS: {
      return {
        ...state,
        allTags: state?.allTags?.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          } else {
            return item
          }
        }),
        selectedTag: action.payload,
      }
    }
    case REMOVE_TAG_SUCCESS: {
      return {
        ...state,
        allTags: state.allTags.filter((tag) => {
          return tag.id !== action.payload
        }),
      }
    }
    case SET_ALL_TAGS: {
      return {
        ...state,
        allTags: action.payload,
      }
    }
    case SET_SEARCH_PENDING_STATUS: {
      return {
        ...state,
        pending: action.payload,
      }
    }
    case SET_TAGS: {
      return {
        ...state,
        tags: action.payload,
      }
    }
    case SET_MENTIONS: {
      return {
        ...state,
        mentions: action.payload,
      }
    }
    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        results: action.payload,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
